import * as React from 'react';
import {
  CssBaseline,
  TextField,
  FormControlLabel,
  Checkbox,
  Grid,
  Box,
  Typography,
  Container,
  Alert,
  Snackbar,
} from '@mui/material';
import {AlertColor} from '@mui/material/Alert';
import LoadingButton from '@mui/lab/LoadingButton';
import {useSelector, useDispatch} from 'react-redux'
import {Copyright} from '../commons/Menu';
import {RootState} from '../../store/store';
import http from '../../http/axios_auth';
import {API} from '../../http/api';
import {Utils} from '../../utils/constantes';
import {Auth, login, Session, UserMe} from "../../store/features/sessionSlice";
import {useNavigate} from "react-router-dom";
import secureLocalStorage from "react-secure-storage";

export default function SignIn() {

  const auth: Auth = useSelector((state: RootState) => state.auth)
  const dispatch = useDispatch()
  const [show, setShow] = React.useState({open: false, message: "", type: "success"});
  const ls: any = secureLocalStorage;
  const navigate = useNavigate();

  const handleClose = (event?: React.SyntheticEvent | Event, reason?: string) => {
    if (reason === 'clickaway') {
      return;
    }

    setShow({open: false, message: "", type: "success"});
  };

  const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    const data = new FormData(event.currentTarget);


    http.defaults.headers.post['Authorization'] = "Basic " + btoa(Utils.clientId + ':' + Utils.clientSecret);
    dispatch(login({loading: true}))

    http.post(API.login, {
      "username": data.get('email'),
      "password": data.get('password'),
      "grant_type": "password"
    }).then((response) => {
      var session: Session = response.data as Session;
      http.defaults.headers.get['Authorization'] = session.token_type + ' ' + session.access_token;
      http.get(API.user_me).then((resp) => {
        var user_me: UserMe = resp.data as UserMe;
        ls.setItem('auth', {session: session, user_me: user_me})
        dispatch(login({loading: false, session: session, user_me: user_me}))
        window.location.href = "/"
      });
    }).catch((error) => {
      dispatch(login({loading: false, session: undefined}))
      if (error.response && error.response.status === 400) {
        setShow({open: true, message: error.response.data.error_description, type: "error"});
      } else {
        setShow({open: true, message: error.message, type: "error"});
      }
      console.log(error);
    })
  };


  React.useEffect(() => {
    if (auth.session !== undefined && auth.user_me !== undefined) {
      navigate("/")
    }
  })



  return (
    <div style={{
      width: '100%',
      height: '100vh',
      minWidth: '100% !impportant',
      minHeight: '100%',
      backgroundSize: "cover",
      position: "absolute",
      backgroundImage: "url(" + window.location.origin + "/assets/images/apollo_gb.jpg" + ")",

    }} >
      <Container component="main" maxWidth="xs" style={{backgroundColor: "#FFFFFFCC", marginTop: 8, marginBottom: 8, borderRadius: 15}}>
        <CssBaseline />
        <Box

          sx={{

            marginTop: 8,
            marginBottom: 8,
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
          }}
        >
          <p style={{textAlign: "center"}}>
            <img width={200} src={window.location.origin + "/assets/images/apollo_logo.png"} alt="Logo" />
          </p>
          <Typography component="h1" variant="h5">
            Sign in
          </Typography>
          <Box component="form" onSubmit={handleSubmit} noValidate sx={{mt: 1}}>
            <TextField
              margin="normal"
              required

              fullWidth
              id="email"
              label="Email Address"
              name="email"
              autoComplete="email"
              autoFocus
            />
            <TextField
              margin="normal"
              required
              fullWidth
              name="password"
              label="Password"
              type="password"
              id="password"
              autoComplete="current-password"
            />
            <FormControlLabel
              control={<Checkbox value="remember" color="secondary" />}
              label="Remember me"
            />
            <LoadingButton
              type="submit"
              fullWidth
              loading={auth.loading}
              variant="contained"
              sx={{mt: 3, mb: 2}}
            >
              Sign In
            </LoadingButton>
            <Snackbar open={show.open} autoHideDuration={6000} anchorOrigin={{vertical: 'top', horizontal: 'center'}} onClose={handleClose}>
              <Alert onClose={handleClose} severity={show.type as AlertColor} sx={{width: '100%'}}>
                {show.message}
              </Alert>
            </Snackbar>
            <Grid container>
              <Grid item xs>
                {/*<Link href="#" variant="body2">
                  Forgot password?
                </Link> */
                }
              </Grid>
              <Grid item>
                {/*<Link href="#" variant="body2">
                  {"Don't have an account? Sign Up"}
                </Link>*/}
              </Grid>
            </Grid>
          </Box>
        </Box>
        <Copyright sx={{mt: 8, mb: 4, pb: 4}} />
      </Container>
    </div>
  );
}
