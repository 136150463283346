import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import type { RootState } from '../store'
import secureLocalStorage from "react-secure-storage";

export interface UserMe {
  id:          number;
  email:       string;
  first_name:  string;
  last_name:   string;
}

export interface Session {
  access_token:  string;
  expires_in:    number;
  token_type:    string;
  scope:         string;
  refresh_token: string;
  
}

export interface Auth{
  session?: Session;
  user_me?: UserMe;
  loading: boolean;
}
// Define the initial state using that type
const ls: any = secureLocalStorage.getItem('auth');

const initialState: Auth = ls ==null ? {
  session:  undefined,
  user_me: undefined,
  loading : false
}:{
  session:  ls.session,
  user_me: ls.user_me,
  loading : false
}

export const sessionSlice = createSlice({
  name: 'auth',

  initialState,
  reducers: {
    authloading: (state, action: PayloadAction<Auth>) => {
      state.loading=action.payload.loading
    },
    login: (state, action: PayloadAction<Auth>) => {
      // state.session=action.payload.session,
      state.user_me=action.payload.user_me
    },
    logout: (state) => {
      // state.session=undefined,
      // state.user_me=undefined,
      state.loading=false
    },
   
    refresh_token: (state, action: PayloadAction<Session>) => {
      state.session = action.payload
    },
  },
})

export const { login, logout, refresh_token } = sessionSlice.actions
export const selectAuth = (state: RootState) => state.auth

export default sessionSlice.reducer