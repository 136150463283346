import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import type { RootState } from '../store'


export interface Client {
  id:         number;
  name:       string;
  fullname:   string;
  url_label:  string;
}
export interface Config {
  client:       Client;
  conf_name:    string;
  config_datas: any;
}


export interface Comparison{
  envone?: Config ;
  envtwo?: Config ;
  loading: boolean;
}
// Define the initial state using that type
const initialState: Comparison = {
  envone:  undefined,
  envtwo: undefined,
  loading:  false
}

export const comparisonSlice = createSlice({
  name: 'comparison',

  initialState,
  reducers: {
    set_loading:(state, action: PayloadAction<Comparison>) => {
      state.loading=action.payload.loading
    },
    
    set_env_one: (state, action: PayloadAction<Comparison>) => {
      state.envone=action.payload.envone
    },
    set_env_two: (state, action: PayloadAction<Comparison>) => {
      state.envtwo=action.payload.envtwo
    },
    // clear_comparison: (state) => {
    //   state.envtwo=undefined,
    //   state.envone=undefined
    // },
  },
})

// export const { set_env_one, set_env_two, clear_comparison } = comparisonSlice.actions
export const selectComparison= (state: RootState) => state.comparison

export default comparisonSlice.reducer