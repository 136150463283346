import * as React from 'react';
import {createBrowserRouter} from "react-router-dom";
import App from "../App";
import SignIn from "../views/auth/SignIn";


const router = createBrowserRouter([
  {
    path: "*",

    element: (
      <App />
    )
  },
  {
    path: "login",
    element: <SignIn />,

  },
]);

export default router;
