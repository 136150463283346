import * as  React from 'react';
import {
  CssBaseline,
  Box,
  Toolbar,
  IconButton,
  Typography,
  Menu,
  List,
  Divider,
  Container,
  Avatar,
  Tooltip,
  MenuItem
} from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import {mainListItems, secondaryListItems} from './listItems';
import {AppBar} from './Header';
import {Copyright, Drawer} from './Menu';

import http from '../../http/axios_auth';
import {API} from '../../http/api';
import {logout} from "../../store/features/sessionSlice";
import {useNavigate, Route, Routes} from 'react-router-dom';
import Riverflows from '../pages/Riverflows';
import Confchecher from '../pages/Confchecker';
import secureLocalStorage from 'react-secure-storage';
import {useDispatch, useSelector} from 'react-redux';
import {RootState} from '../../store/store';
import {Utils} from '../../utils/constantes';


const settings = ['Logout'];

function HommePageContent() {

  const auth: any = useSelector((state: RootState) => state.auth)

  const [open, setOpen] = React.useState(true);
  const toggleDrawer = () => {
    setOpen(!open);
  };
  const navigate = useNavigate();
  const [anchorElNav, setAnchorElNav] = React.useState<null | HTMLElement>(null);
  const [anchorElUser, setAnchorElUser] = React.useState<null | HTMLElement>(null);
  const dispatch = useDispatch()
  const handleOpenNavMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorElNav(event.currentTarget);
  };
  const handleOpenUserMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorElUser(event.currentTarget);
  };

  const handleCloseNavMenu = () => {
    setAnchorElNav(null);
  };

  const handleCloseUserMenu = (key: string) => {

    if (key === 'Logout' as string) {

      http.post(API.logout, {token: auth.session.refresh_token, client_id: Utils.clientId}).then(response => {
        dispatch(logout())
        secureLocalStorage.clear()
        navigate('/login')
      }).catch((error) => {
        dispatch(logout())
        secureLocalStorage.clear()
        navigate('/login')
      })
      /*
      http.post('/auth/revoke_token/',
        {'token':auth.session?.refresh_token,'client_id': Utils.clientId}
      ).then((response)=>{
       
      }).catch((error) =>{

      })*/
    }
    setAnchorElUser(null);

  };

  const handleCloseUserMenuNone = () => {

    setAnchorElUser(null);
  };

  function stringToColor(string: string) {
    let hash = 0;
    let i;

    /* eslint-disable no-bitwise */
    for (i = 0; i < string.length; i += 1) {
      hash = string.charCodeAt(i) + ((hash << 5) - hash);
    }

    let color = '#';

    for (i = 0; i < 3; i += 1) {
      const value = (hash >> (i * 8)) & 0xff;
      color += `00${value.toString(16)}`.slice(-2);
    }
    /* eslint-enable no-bitwise */

    return color;
  }

  const stringAvatar = (name: string) => {
    return {
      sx: {
        bgcolor: stringToColor(name),
      },
      children: `${name.split(' ')[0][0]}${name.split(' ')[1][0]}`,
    };
  }

  return (
    <Box sx={{display: 'flex'}}>
      <CssBaseline />
      <AppBar position="absolute" open={open}>
        <Toolbar
          sx={{
            pr: '24px', // keep right padding when drawer closed
          }}
        >
          <IconButton
            edge="start"
            color="inherit"
            aria-label="open drawer"
            onClick={toggleDrawer}
            sx={{
              marginRight: '36px',
              ...(open && {display: 'none'}),
            }}
          >
            <MenuIcon />
          </IconButton>
          <Typography
            component="h1"
            variant="h6"
            color="inherit"
            noWrap
            sx={{flexGrow: 1}}
          >
            Apollo Tooling
          </Typography>
          {/*<IconButton color="inherit">
              <Badge badgeContent={4} color="secondary">
                <NotificationsIcon />
              </Badge>
            </IconButton>*/}

          <Tooltip title="">
            <IconButton onClick={handleOpenUserMenu} sx={{p: 0}}>
              {
                <Avatar {...stringAvatar(auth.user_me!.first_name + ' ' + auth.user_me!.last_name)} />
              }
            </IconButton>

          </Tooltip>
          <Menu
            sx={{mt: '45px'}}
            id="menu-appbar"
            anchorEl={anchorElUser}
            anchorOrigin={{
              vertical: 'top',
              horizontal: 'right',
            }}
            keepMounted
            transformOrigin={{
              vertical: 'top',
              horizontal: 'right',
            }}
            open={Boolean(anchorElUser)}
            onClose={handleCloseUserMenuNone}
          >
            {settings.map((setting) => (
              <MenuItem key={setting} onClick={() => {handleCloseUserMenu(setting)}}>
                <Typography textAlign="center">{setting}</Typography>
              </MenuItem>
            ))}
          </Menu>
        </Toolbar>
      </AppBar>
      <Drawer variant="permanent" open={open}>
        <Toolbar
          sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'flex-end',
            px: [1],
          }}
        >
          <img src={window.location.origin + "/assets/images/apollo_logo.png"} width={'75%'} /><span className={"mr-1"} />
          <IconButton onClick={toggleDrawer}>
            <ChevronLeftIcon />
          </IconButton>
        </Toolbar>
        <Divider />
        <List component="nav">
          {mainListItems}
          <Divider sx={{my: 1}} />
          {secondaryListItems}
        </List>
      </Drawer>
      <Box
        component="main"
        sx={{
          backgroundColor: (theme) =>
            theme.palette.mode === 'light'
              ? theme.palette.grey[100]
              : theme.palette.grey[900],
          flexGrow: 1,
          height: '100vh',
          overflow: 'auto',
        }}
      >
        <Toolbar />
        <Container style={{minHeight: '99vh'}} maxWidth="lg" sx={{mt: 4, mb: 4}}>
          <Routes>
            <Route path="riverflows" element={<Riverflows />} />
            <Route path="confchecker" element={<Confchecher />} />
          </Routes>
          <Copyright sx={{pt: 4}} />
        </Container>
      </Box>
    </Box>
  );
}

export default function HommePage() {

  return <HommePageContent />;
}


