import axios from "axios";
import {Utils} from '../utils/constantes'

const http = axios.create({
    baseURL: Utils.baseUrl,
    timeout: 15000,
    headers: {'Content-Type': 'application/x-www-form-urlencoded', "Access-Control-Allow-Origin":"*", "Accept":"*/*"}
  });

  http.interceptors.request.use(
    config => {
     
          return config;
      },
      error => {
          return Promise.reject(error);
      }
  );

export default http; 