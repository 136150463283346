import * as React from 'react';
import {fetchEnvs, fetchConf, fetchClients, fetchConfNames} from '../../http/api';
import {Paper, FormGroup, FormControlLabel, Grid, Switch, Alert, AlertColor, Box, MenuItem, Snackbar, TextField, Typography} from '@mui/material';
import ReactDiffViewer, {DiffMethod} from 'react-diff-viewer';
import {Env, ConfName, Client} from "../../store/features/envSlice";
import { forwardRef, RefObject } from 'react';
import { AnyARecord } from 'dns';



const RENDER_STYLES = {
  variables: {
    light: {
      codeFoldGutterBackground: "#6F767E",
      codeFoldBackground: "#E2E4E5"
    }
  }
};

interface EnvSelectorType {
  setQueryData: any,
  setShow: any,
  label: string
}

const EnvSelector = React.forwardRef<any, EnvSelectorType>(
  ({ setQueryData, setShow, label }, ref) => {
  const [env, setEnv] = React.useState({} as Env);
  const [envlist, setEnvlist] = React.useState([] as Env[]);

  React.useEffect(() => {
    fetchEnvs().then((response) => {
      setEnvlist(response.data.results)
    }).catch((error) => {
      if (error.response && error.response.status === 400) {
        setShow({open: true, message: error.response.data.error_description, type: "error"});
      } else {
        setShow({open: true, message: error.message, type: "error"});
      }
    })
  }, [])

  const handleChangeEnv = (event: React.ChangeEvent<HTMLInputElement>) => {
    for (let _env of envlist) {
      if (_env.env_code == event.target.value) {
        setEnv(_env);
        setQueryData({env: _env});
        return;
      }
    }
  }

  const handleRefresh = () => {
    setEnv({} as Env);
  };

  React.useImperativeHandle(ref, () => ({
    handleRefresh
  }));

  return <>
    <Typography variant='h5'>Environment <span>{env.env_code ? "[" + env.env_name + "]" : label}</span></Typography>
    <Box component="form" onSubmit={() => {}} noValidate sx={{mt: 1}}>
      <TextField
        id={`env-${label}`}
        select
        label="Select an environment"
        value={env.env_code || ""}
        onChange={handleChangeEnv}
        variant="filled"
        fullWidth
      >
        {envlist.map((option) => (
          <MenuItem key={option.id} value={option.env_code}>
            {option.env_name}
          </MenuItem>
        ))}
      </TextField>
    </Box>
  </>

});

interface confChecked {
  setQueryData: any,
  setShow: any,
  label: string
}

const ConfSelector = forwardRef<any, confChecked>(
  ({ setQueryData, setShow, label }, ref) => {
  const [env, setEnv] = React.useState({} as Env);
  const [envlist, setEnvlist] = React.useState([] as Env[]);
  const [clientenv, setClientenv] = React.useState({} as Client);
  const [clientenvList, setClientenvList] = React.useState([] as Client[]);
  const [confName, setConfName] = React.useState({} as ConfName);
  const [confNameList, setConfNameList] = React.useState([] as ConfName[]);


  React.useEffect(() => {
    if (env.id !== undefined) {
      fetchClients(env).then((response) => {
        setClientenvList(response.data);
        setClientenv({} as Client);
        clearConf();
      });
    }
  }, [env]);

  React.useEffect(() => {
    if (clientenv.id !== undefined) {
      fetchConfNames(env).then((response) => {
        setConfNameList(response.data);
      })
    }
  }, [clientenv]);

  React.useEffect(() => {
    if (confName.name !== undefined) {
      setQueryData({env, clientenv, confName});
    }
  }, [clientenv, confName]);

  const clearConf = () => {setConfName({} as ConfName); setQueryData({})};

  const handleChangeEnv = (event: React.ChangeEvent<HTMLInputElement>) => {
    setConfNameList([]);
    setClientenvList([]);
    for (let _env of envlist) {
      if (_env.env_code == event.target.value) {
        setEnv(_env);
        return;
      }
    }
  }

  const handleChangeConfEnv = (event: React.ChangeEvent<HTMLInputElement>) => {
    setConfName({name: event.target.value});
  };

  const handleChangeClientEnv = (event: React.ChangeEvent<HTMLInputElement>) => {
    for (let _client of clientenvList) {
      if (_client.id.toString() === event.target.value.toString()) {
        setClientenv(_client);
        return;
      }
    }
  }

  const handleRefresh = () => {
    setEnv({} as Env);
    setClientenv({} as Client);
    setConfName({} as ConfName)
  };

  React.useImperativeHandle(ref, () => ({
    handleRefresh
  }));

  React.useEffect(() => {
    fetchEnvs().then((response) => {
      setEnvlist(response.data.results)
    }).catch((error) => {
      if (error.response && error.response.status === 400) {
        setShow({open: true, message: error.response.data.error_description, type: "error"});
      } else {
        setShow({open: true, message: error.message, type: "error"});
      }
    })
  }, [])

  return <>
    <Typography variant='h5'>Environment <span>{env.env_code ? "[" + env.env_name + "]" : label}</span></Typography>
    <Box component="form" onSubmit={() => {}} noValidate sx={{mt: 1}}>
      <TextField
        id={`env-${label}`}
        select
        label="Select an environment"
        value={env.env_code || ""}
        onChange={handleChangeEnv}
        variant="filled"
        fullWidth
      >
        {envlist.map((option) => (
          <MenuItem key={option.id} value={option.env_code}>
            {option.env_name}
          </MenuItem>
        ))}
      </TextField>
      <br />
      <div style={{marginTop: 15}} />
      {
        env.id && <TextField
          id={`confClient-${label}`}
          select
          label="Select a client"
          value={clientenv.id || ""}
          onChange={handleChangeClientEnv}
          helperText=""
          variant="filled"
          fullWidth
        >
          {clientenvList.map((option) => (
            <MenuItem key={option.id} value={option.id}>
              {option.fullname}
            </MenuItem>
          ))}
        </TextField>
      }
      <br />
      <div style={{marginTop: 15}} />
      {
        clientenv.id && <TextField
          id={`confName1-${label}`}
          select
          label="Select configuration name"
          value={confName.name || ""}
          onChange={handleChangeConfEnv}
          helperText=""
          variant="filled"
          fullWidth
        >
          {confNameList.map((option) => (
            <MenuItem key={option.name} value={option.name}>
              {option.name}
            </MenuItem>
          ))}
        </TextField>

      }
    </Box>
  </>
});

interface QueryDataType {
  env?: string
}

export default function Confchecher() {
  const rightSelectorRef = React.useRef<any>(null);
  const leftSelectorRef = React.useRef<any>(null);
  const [leftCode, setLeftCode] = React.useState("");
  const [rightCode, setRightCode] = React.useState("");
  const [show, setShow] = React.useState({open: false, message: "", type: "success"});
  const [linked, setLinked] = React.useState(true);
  const [leftQueryData, setLeftQueryData] = React.useState({} as QueryDataType);
  const [rightQueryData, setRightQueryData] = React.useState({} as QueryDataType);

  const _fetchConf = (data: any, setCode: any) => {
    if (data.env && data.clientenv && data.clientenv.id !== undefined && data.confName && data.confName.name !== undefined) {
      fetchConf(data.env, data.clientenv, data.confName)
        .then((response) => {setCode(JSON.stringify(response.data.data, undefined, 2))})
        .catch((err) => {setShow({open: true, message: "Failed to load conf", type: "error"})});
    }
    else {
      setCode("");
    }
  }

  React.useEffect(() => {
    _fetchConf(leftQueryData, setLeftCode);
    if (linked) {
      setRightQueryData((data) => ({...data}));
    }

  }, [leftQueryData]);

  React.useEffect(() => {
    const data = linked ? {...leftQueryData, env: rightQueryData.env} : rightQueryData;
    _fetchConf(data, setRightCode);
  }, [rightQueryData]);

  const handleClose = (event?: React.SyntheticEvent | Event, reason?: string) => {
    if (reason === 'clickaway') {
      return;
    }

    setShow({open: false, message: "", type: "success"});
  };

  const handleLinkedChange = (event: any) => {
    setLinked(event.target.checked);
    setRightQueryData({});
  }

  const handleRefresh = () => {
    setLeftCode("");
    setRightCode("");
    if (rightSelectorRef.current)
      rightSelectorRef.current.handleRefresh();
    if (leftSelectorRef.current)
      leftSelectorRef.current.handleRefresh();
  };

  return <>
    <Grid container spacing={3}>
      <Grid item xs={12} md={12} lg={12}>
        <Typography variant='h4' className='bold'>Configurations Compare</Typography>
        <button onClick={handleRefresh}>Refresh</button>
      </Grid>
      <Grid item xs={12} md={12} lg={12}>
        <Paper sx={{p: 2, display: 'flex', flexDirection: 'column'}}>
          <Grid container alignContent="space-between" spacing={3} justifyItems="stretch">
            <Grid item xs={12} md={6} lg={6}>
              <ConfSelector setQueryData={setLeftQueryData} setShow={setShow} label="A" ref={leftSelectorRef}/>
            </Grid>
            <Grid item xs={12} md={6} lg={6}>
              {linked ?
                <EnvSelector setQueryData={setRightQueryData} setShow={setShow} label="B" ref={rightSelectorRef}/>
                :
                <ConfSelector setQueryData={setRightQueryData} setShow={setShow} label="B" ref={rightSelectorRef}/>
              }
              <FormGroup>
                <FormControlLabel control={<Switch checked={linked} onChange={handleLinkedChange} />}
                  label="linked" />
              </FormGroup>
            </Grid>
            <Snackbar open={show.open} autoHideDuration={6000} anchorOrigin={{vertical: 'bottom', horizontal: 'center'}} onClose={handleClose}>
              <Alert onClose={handleClose} severity={show.type as AlertColor} sx={{width: '100%'}}>
                {show.message}
              </Alert>
            </Snackbar>

          </Grid>
          <div style={{marginTop: 30}} />

          <ReactDiffViewer
            oldValue={leftCode}
            newValue={rightCode}
            splitView={true}
            compareMethod={DiffMethod.WORDS}
            styles={RENDER_STYLES}
          />

        </Paper>
      </Grid>

    </Grid>
  </>
}
