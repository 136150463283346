import axios from "axios";
import {Utils} from '../utils/constantes'
import  secureLocalStorage  from  "react-secure-storage";

const ls = secureLocalStorage;
const token:any = ls.getItem('auth');

const http = axios.create({
    baseURL: Utils.baseUrl,
    timeout: 15000,
    headers: {'Content-Type': 'application/json', "Accept":"*/*", 'Authorization':token===null?"":token.session.token_type+' '+token.session.access_token}
  });

export default http;
