import React from 'react';
import './App.css';
import HommePage from './views/commons/Main';
import {useSelector} from 'react-redux';
import {RootState} from './store/store';
import {useNavigate} from 'react-router-dom';
import CircularProgress from '@mui/material/CircularProgress';

function App() {
  const navigate = useNavigate();
  const auth: any = useSelector((state: RootState) => state.auth)
  React.useEffect(() => {
    if (auth.session === undefined || auth.user_me === undefined) {
      navigate("/login")
    }
  })
  return (
    (auth.session === undefined || auth.user_me === undefined) ? <div><CircularProgress style={{position: 'absolute', top: '50%', left: '50%'}} /></div> : <HommePage />
  );
}

export default App;
