import { configureStore } from '@reduxjs/toolkit'
import sessionReducer from './features/sessionSlice'
import configReducer from './features/configSlice'

const store = configureStore({
  reducer: {
    auth: sessionReducer,
    comparison: configReducer,
  },
})

export default store

export type RootState = ReturnType<typeof store.getState>
export type AppDispatch = typeof store.dispatch