import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import router from './router/Router';
import reportWebVitals from './reportWebVitals';
import { createTheme, ThemeProvider, ThemeOptions } from '@mui/material/styles';
import {
  RouterProvider,
} from "react-router-dom";
import { Provider } from 'react-redux'
import store from './store/store'

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);

const theme = createTheme({
  palette: {
    primary: {
      main: '#006AFF',
      contrastText: '#ffffff',
    },
    secondary: {
      light: '#0066ff',
      main: '#000000',
      contrastText: '#ffffff',
    },
    contrastThreshold: 3,
    tonalOffset: 0.2, 
  }
});

root.render(
  <Provider store={store}>
    <React.StrictMode>
      <ThemeProvider theme={theme}>
          <RouterProvider router={router} />
      </ThemeProvider>
    </React.StrictMode>
  </Provider>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
