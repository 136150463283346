import axios from "axios";
import http from './axios';

import {Env, ConfName, Client, selectedYear} from "../store/features/envSlice";

export class API {
    static login = "/auth/token/";
    static logout = "/auth/revoke_token/";
    static user_me = "/auth/me/";
    static get_environments = "/environments";
    static set_runs = "/runs/";
}

const BASIC_HEADERS = {'Content-Type': 'application/json'};
const getHeadersWithAuth = (key: string) => ({...BASIC_HEADERS, 'Authorization': `Basic ${key}`});

export const fetchEnvs = () => http.get(API.get_environments);

export const fetchConfNames = (env: Env) => axios.get(env.base_url + "/configuration-names/", {
  timeout: 15000,
  headers: getHeadersWithAuth(env.env_api_key)
});

export const fetchClients = (env: Env) => axios.get(env.base_url + "/clients/", {
  timeout: 15000,
  headers: getHeadersWithAuth(env.env_api_key)
});

export const fetchConf = (env: Env, client: Client, confName: ConfName) => {
  const url = `${env.base_url}/configuration/${client.id}/${confName.name}/`;
  return axios.get(url, {timeout: 15000, headers: getHeadersWithAuth(env.env_api_key)});
};

export const scheduleFlowRuns = (deployment: string, runPhase: string, clientYears: selectedYear[]) => {
  const flowRunsData =  {
    dep: deployment,
    clientYears,
    run_phase: runPhase
  };

  return http.post(API.set_runs, flowRunsData)
    .then(response => response.data)
    .catch(error => {
      console.error('Error fetching flow runs:', error);
      throw error;
    })
}
